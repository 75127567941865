@import "../App.css";

.table__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.table__container .table__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px;
    gap: 50px;
    border-bottom: 1px solid rgba(104, 104, 104, 0.2);   
}

.table__container .table__row.bold {
    font-weight: bold;
    font-size: 1.3rem;
    border-bottom: none;
    border-top: 2px solid var(--navy);
}

.table__container .table__row.bold_small:not(:last-child) {
    /* font-weight: bold; */
    font-size: 1rem;
}

.table__container .table__row.overline_row {
    border-top: 2px solid var(--navy);
}

.table__container .table__row.negative .table__cell {
    color: var(--red);
}

.table__container .table__row.bold .table__cell:last-child {
    /* dotted underline */
    border-bottom: 2px dotted var(--navy);
}