@import "../App.css";

:root {
    --slider-primary: #EA5178;
    --slider-bg: #ea51772c;
    --slider-filled-bg: #ea5177c0;
}

.discounts {
    background-color: var(--white);
    width: 100%;
    border-radius: 10px;
    padding: 10px 30px;

    display: flex;
    justify-content: left;
    align-items: center;
}

.discounts .label {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    color: var(--navy);
    text-transform: uppercase;
    letter-spacing: 1px;
    flex: 8;
}

.discounts .discounts-content {
    width: 100%;
    flex: 50;
    height: auto;
    padding: 15px 25px;
    overflow: hidden;
}

.slider {
    width: 100%;
    height: 50px;
}

.thumb {
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    background-color: var(--slider-primary);
    border-radius: 20px;
    cursor: pointer;

    color: var(--white);
    font-size: 1.2rem;
    font-weight: 600;
    box-sizing: content-box;
    padding: 0 20px;
}

.thumb::after {
    content: "%";
}

.thumb.active {
    filter: contrast(1.2);
}

.track {
    position: relative;
    background: var(--slider-filled-bg);
    border-radius: 20px;
}

.track.track-1 {
    background: var(--slider-bg);
}

.slider .track {
    top: 10px;
    height: 30px;
}

.slider .thumb {
    top: 1px;
    height: 48px;
    line-height: 38px;
}

.marks {
    background-color: red;
    width: 2px;
    height: 10px;
}