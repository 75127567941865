@import "../App.css";

.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-size: cover;
}

.calc-page {
    height: 100VH;
    width: 100vw;
    background-size: cover;
    overflow-x: hidden;
}

.calc-page .content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100vh - 50px);
}

.calc-page .content .pane {
    padding: 10px 20px;
    box-sizing: border-box;
    height: auto;
}

.calc-page .content .settings {
    flex: 1;
    padding-right: 25px;
}


.calc-page .content .finalcosts {
    width: 500px;
    padding: 30px 30px;
    background-color: var(--white);
    border-radius: 10px;
    margin: 30px 0;
    margin-right: 30px;
    margin-bottom: 12px;
}

.split {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    gap: 50px;
}

.split-around {
    display: flex;
    justify-content: left;
    padding: 0 30px;
    gap: 50px;
}

.pane.finalcosts {
    display: flex;
    flex-direction: column;
    justify-content: left;
    height: 100%;
    width: 100%;
    align-items: center;
    color: var(--navy);
}

.pane.finalcosts .finalCostPriceSmall {
    font-size: 18px;
    font-weight: bold;
    margin: 3px 0;
}

.pane.finalcosts .finalCostPriceSmaller {
    font-size: 14px;
    font-weight: bolder;
    margin: 5px 0;
}

.finalCostPrice {
    font-size: 60px;
    font-weight: bold;
    margin: 0;
}

.table {
    width: 100%;
}

.table thead tr.negative {
    color: red;
}

.table thead tr:first-child {
    border-top: 1px solid transparent;
}

.showEmptyCharges {
    width: 100%;
}

tr.grey {
    opacity: 0.5;
    transition: 0.2s ease-out;
    line-height: 10px;
}

tr.grey:hover {
    opacity: 0.9;
}

.pane.finalcosts .breakdown-title {
    font-size: 1.4rem;
    font-weight: bold;
    margin: 15px 0;
    margin-top: 30px;
    color: var(--pink);
}

.finalcosts-buttons {
    display: flex;
    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;
    gap: 10px;
}

.finalcosts-buttons button {
    flex: 1;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 0.8rem;
    font-weight: bold;
    white-space: nowrap;
    color: var(--white);
    background-color: var(--pink);
    cursor: pointer;
    transition: 0.2s ease-out;
}

.finalcosts-buttons button.blue-button {
    background-color: var(--aqua);
}

.finalcosts-buttons button.transparent-button {
    background-color: transparent;
    color: var(--navy);
    border: 1px solid var(--navy);
}

.finalcosts-buttons button.pink-button:hover {
    filter: brightness(1.2);
}

.finalcosts-buttons button.blue-button:hover {
    filter: brightness(1.1);
}

.finalcosts-buttons button.transparent-button:hover {
    background-color: var(--navy);
    color: var(--white);
}