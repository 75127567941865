@import "../App.css";

.dq {
    width: 800px !important;
}

.downloadQuoteInput {
    outline: none;
    padding: 5px 10px;
}

.count-grid {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0px;
}

.split-dq {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
}

.sitemap {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    padding: 10px 20px;
}

.sitemap-item {
    width: 100%;
    padding: 5px;
}

.sitemap-item-name {
    display: flex;
    justify-content: space-between;
}

.sitemap-item-remove {
    cursor: pointer;
    font-size: 1rem;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    padding: 0px 5px;
}

.sitemap-item-remove:hover {
    opacity: 1;
}

.sitemap-item-features {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 10px;
}

.sitemap-item-feature {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding: 5px 0px;
}

.right-dq {
    max-height: 650px;
    overflow-y: auto;
}

.dq-input {
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 10px;
}

.dq-input input {
    flex: 2;
    padding: 10px;
    box-sizing: border-box;
}

.dq-input button {
    flex: 1;
}

.downloadQuoteInputBox {
    /* padding: 10px; */
    outline: none;
    border: none;
}

.downloadQuoteInputBox:focus {
    outline: none;
    border: none;
}

.downloadQuoteButton {
    width: 100%;
    padding: 10px;
    margin: 0;
    border: none;
    background-color: var(--pink);
    color: var(--white);
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.downloadQuoteButton:hover {
    background-color: #d34c6c;
}