@import "../App.css";

nav {
    display: sticky;
    width: 100%;
    height: 100px;
    color: var(--white);
    margin-top: 10px;
}

nav .navbar {
    display: grid;
    grid-template-columns: 1fr 480px;
    height: 100%;
}

nav .navbar .navbar-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    padding-right: 90px;
}

nav .navbar .navbar-left .logo {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 30px;
}

nav .navbar .navbar-left .logo .logo-img {
    max-height: 50px;
}

nav .navbar .navbar-left .title {
    font-size: 1.75rem;
    font-weight: bold;
}

nav .navbar .navbar-left .website-name {
    background-color: var(--pink);
    border-radius: 12px;
    padding: 12px 20px;
}

nav .navbar .navbar-left .website-name .name {
    font-size: 1.2rem;
    font-weight: bold;
}

nav .navbar .navbar-right {
    display: flex;
    justify-content: right;
    padding: 0 30px;
    gap: 30px;
}

nav .navbar .navbar-right button {
    text-transform: uppercase;
    letter-spacing: 1.4px;
    font-weight: 600;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    transition: all 0.2s ease;
}

nav .navbar .navbar-right button:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 1);
}