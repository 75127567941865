@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
:root {
  --pink: #EA5178;
  --aqua: #66B8D9;
  --white: #FFFFFF;
  --grey: #707070;
  --light-grey: #B9B9B9;
  --navy: #1A3C47;
}

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

span.dot {
  color: var(--pink);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}