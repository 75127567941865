@import "../App.css";

.checkboxSelect {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.checkboxSelect.hidden {
    display: none;
}

.checkboxSelect label {
    margin: 0;
}

.checkboxSelect {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: auto;
    width: 100%;
    border-radius: 50px;
    background-color: var(--white);
    border: 1px solid var(--light-grey);
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.checkboxSelect .checkboxSelect-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--gray);
    margin-right: 15px;
    transition: all 0.2s ease-in-out;
}

.checkboxSelect .checkboxSelect-square {
    min-width: 20px;
    min-height: 20px;
    border-radius: 5px;
    border: 1px solid var(--gray);
    margin-right: 15px;
    transition: all 0.2s ease-in-out;
}

.checkboxSelect .checkboxSelect-name {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    color: var(--navy);
    transition: all 0.2s ease-in-out;
}

.checkboxSelect.deprecated .checkboxSelect-name {
    text-decoration: line-through;
    opacity: 0.5;
}

.checkboxSelect .checkboxSelect-price {
    margin-left: 5px;
    color: var(--pink);
    transition: all 0.2s ease-in-out;
}

.checkboxSelect-checkmark {
    fill: var(--white);
    color: white;
    margin-left: auto;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    stroke: white;
    stroke-width: 5px;
}

.checkboxSelect.selected {
    background-color: var(--pink);
    border: 1px solid var(--pink);
}

.checkboxSelect.selected .checkboxSelect-circle {
    border: 4px solid var(--white);
}

.checkboxSelect.selected .checkboxSelect-square {
    border: 4px solid var(--white);
}

.checkboxSelect.selected .checkboxSelect-name {
    color: var(--white);
}

.checkboxSelect.selected .checkboxSelect-price {
    color: var(--white);
}

.checkboxSelect.selected .checkboxSelect-checkmark {
    opacity: 1;
}