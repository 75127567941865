@import "../App.css";

.hosting-content {
}

.hosting-content button {
    background-color: var(--white);
    border: 2px solid var(--light-grey);
    margin: 10px 0;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    outline: none;
    transition: all 0.2s ease-in-out;
}

.hosting-content button .text {
    font-size: 1rem;
    font-weight: 600;
    color: var(--grey);
    transition: all 0.2s ease-in-out;
}

.hosting-content button .price {
    font-size: 1rem;
    font-weight: 600;
    color: var(--navy);
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
}

.hosting-content button.active {
    background-color: var(--navy);
    border: 2px solid var(--navy);
}

.hosting-content button.active .text {
    color: var(--white);
}

.hosting-content button.active .price {
    color: var(--white);
}

.hosting-select {
    margin-top: 30px;
}
  
.quota {
    display: inline-block;
    color: var(--pink);
    font-weight: bold;
    width: 50px;
    margin-right: 8px;
}
  
.item-name {
    display: inline-block;
    margin: 0;
    font-weight: bold;
    vertical-align: middle;
}

.mega-input-container {
    position: relative;
}

input.mega-input {
    width: 100%;
    height: 40px;
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    padding: 0 20px;
    font-size: 1rem;
    font-weight: 600;
    color: var(--black);
    outline: none;
    transition: all 0.2s ease-in-out;
    margin: 10px 0;
}

input.mega-input.unfilled {
    border: 2px solid var(--pink);
}

.mega-input-container .mega-input-label {
    position: absolute;
    
    font-size: 1rem;
    font-weight: 600;
    color: var(--black);

    top: 30%;
    left: 10px;
}