@import "../App.css";

.price-section {
    padding: 20px 30px;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    margin: 20px 0;
}

.price-section .label {
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0;
    color: var(--navy);
}

.price-section .label::after {
    content: ".";
    color: var(--pink);
}

input[type=checkbox] {
    margin-right: 8px;
}

label {
    display: flex;
    align-items: center;
}

.price-section .package {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}

.price-section .item label {
    margin: 0;
}

.price-section .item label .item-name {
    font-weight: bold;
}

.price-section .item label .item-price {
    font-weight: lighter;
    opacity: 0.5;
    margin-left: 30px;
}

.count-input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    overflow: hidden;
    height: 40px;
    width: 100%;
    margin: 8px 0;
}

.count-input label {
    color: var(--navy);
    font-size: 0.8rem;
    flex: 10;
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    border: 1px solid var(--grey);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding-left: 15px;
    height: 100%;
}

.count-input .input-container {
    flex: 3;
    text-align: center;
    border: none;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}