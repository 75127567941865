.package label {
    display: flex;
    align-items: center;
    margin: 0;
}

.package label .package-name {
    font-weight: bold;
}

.package label .package-price {
    font-weight: lighter;
    opacity: 0.5;
    margin-left: 30px;
}