@import "../App.css";

.input-container {
    position: relative;
    margin: 0;
    height: 100%;
    width: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.input-container input {
    background-color: var(--pink);
    padding: 2px 10px;
    color: var(--white);
    font-size: 1.4rem;
    font-weight: bold;
    width: 100%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border: 1px solid var(--grey);
}