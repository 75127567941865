@import "../App.css";

.checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
    margin-bottom: 10px;
}

.checkboxContainer .name {
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.4px;
}

.eCommerce-input {
    display: flex;
    gap: 10px;
}

.checkbox {
    gap: 10px;
    width: 80px;
    height: 30px;
    background-color: var(--navy);
    border-radius: 50px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.checkbox.off {
    background-color: var(--grey);
}

.checkbox .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 160%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;
    color: var(--white);
    padding: 0 15px;
    transform: translateX(-2%);
    transition: 0.2s ease-in-out;
}

.checkbox.off .content {
    transform: translateX(-38%);
}

.checkbox .content .cb-label {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
}

.checkbox .content .cb-circle {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: var(--white);
}

.checkbox .content .cb-circle circle {
    fill: var(--white);
}